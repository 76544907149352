import { toast } from "react-toastify";

import { http } from '../../http';
import * as actionTypes from "../actionTypes";
import { ChallengeOverview, GetTopChallengeParticipants, GetTopChallengeLeaderboard, GetActivityChallengeData, GetBehaviorChallengeData, GetCompanyUserCount, GetTopChallenges, AdminChallengeOverview, GetAdminActiveandUpcomingChallenges } from "../../constants/apiConstants";

const networkError = (error) => (dispatch) => dispatch({type: actionTypes.NETWORK_ERROR, networkError: error});

const getChallengeOverview = (id, year) => {
  const AuthToken = localStorage.getItem('AUTH_TOKEN');
  return async (dispatch) => {
    try {
      const res = await http.get(`${ChallengeOverview}/${id}/${year}`, {headers: { AUTHTOKEN: AuthToken }});
      if(res.message) {
        toast.error(res.message);
      } else {
        dispatch({type: actionTypes.CHALLENGE_OVERVIEW, challengeOverviewData: res});
      }
    } catch(error) {
      toast.error(error.message);
      dispatch(networkError(error.message));
    }
  }
};

const challengesTopParticipants = (id, year) => {
  const AuthToken = localStorage.getItem('AUTH_TOKEN');
  return async (dispatch) => {
    try {
      const res = await http.get(`${GetTopChallengeParticipants}/${id}/${year}`, {headers: { AUTHTOKEN: AuthToken }});
      if(res.message) {
        toast.error(res.message);
      } else {
        dispatch({type: actionTypes.CHALLENGE_TOP_PARTIPANTS, challengeTopParticpantsData: res});
      }
    } catch(error) {
      toast.error(error.message);
      dispatch(networkError(error.message));
    }
  }
};

const challengesTopLeaderboard = (id, year) => {
  const AuthToken = localStorage.getItem('AUTH_TOKEN');
  return async (dispatch) => {
    try {
      const res = await http.get(`${GetTopChallengeLeaderboard}/${id}/${year}`, {headers: { AUTHTOKEN: AuthToken }});
      if(res.message) {
        toast.error(res.message);
      } else {
        dispatch({type: actionTypes.CHALLENGE_TOP_LEADERS, challengeTopLeadersData: res});
      }
    } catch(error) {
      toast.error(error.message);
      dispatch(networkError(error.message));
    }
  }
};

const fetchChallengesDashBoardAPI = (id, year) => (dispatch) => {
  dispatch(getChallengeOverview(id, year));
  dispatch(challengesTopParticipants(id, year));
  dispatch(challengesTopLeaderboard(id, year));
  dispatch(activityChallengeAPI(id, year));
  dispatch(behaviorChallengeAPI(id, year));
  dispatch(FetchEmployeeCount(id));
  dispatch(getTopChallenges(id));
  dispatch(getAdminChallengeOverview(id, year));
}

const activityChallengeAPI = (id, year) => {
  const AuthToken = localStorage.getItem('AUTH_TOKEN');
  return async (dispatch) => {
    try {
      const res = await http.get(`${GetActivityChallengeData}/${id}/${year}`, {headers: { AUTHTOKEN: AuthToken }});
      if(res.message) {
        toast.error(res.message);
      } else {
        dispatch({type: actionTypes.ACTIVITY_CHALLENGE_DATA, activityChallengeData: res});
      }
    } catch(error) {
      toast.error(error.message);
      dispatch(networkError(error.message));
    }
  }
};

const behaviorChallengeAPI = (id, year) => {
  const AuthToken = localStorage.getItem('AUTH_TOKEN');
  return async (dispatch) => {
    try {
      const res = await http.get(`${GetBehaviorChallengeData}/${id}/${year}`, {headers: { AUTHTOKEN: AuthToken }});
      if(res.message) {
        toast.error(res.message);
      } else {
        dispatch({type: actionTypes.BEHAVIOR_CHALLENGE_DATA, behaviorChallengeData: res});
      }
    } catch(error) {
      toast.error(error.message);
      dispatch(networkError(error.message));
    }
  }
};

const FetchEmployeeCount = (id) => {
  const AuthToken = localStorage.getItem('AUTH_TOKEN');
  return async (dispatch) => {
    try {
      const res = await http.get(`${GetCompanyUserCount}/${id}`, {headers: { AUTHTOKEN: AuthToken }});
      dispatch({ type: actionTypes.SET_ENGAGEMENT_EMPLOYEE_COUNT, count:res.count});
    } catch(error) {
      toast.error(error.message);
    }
  }
}

const getTopChallenges = (id) => {
  const AuthToken = localStorage.getItem('AUTH_TOKEN');
  return async (dispatch) => {
    try {
      const res = await http.get(`${GetTopChallenges}/${id}`, {headers: { AUTHTOKEN: AuthToken }});
      if(res.message) {
        toast.error(res.message);
      } else {
        dispatch({type: actionTypes.GET_TOP_CHALLENGES, topChallenges: res});
      }
    } catch(error) {
      dispatch(networkError(error.message));
    }
  }
};

const getAdminChallengeOverview = (id, year) => {
  const AuthToken = localStorage.getItem('AUTH_TOKEN');
  return async (dispatch) => {
    try {
      const res = await http.get(`${AdminChallengeOverview}/${id}/${year}`, {headers: { AUTHTOKEN: AuthToken }});
      if(res.message) {
        toast.error(res.message);
      } else {
        dispatch({type: actionTypes.ADMIN_CHALLENGE_OVERVIEW, adminChallengeOverviewData: res});
      }
    } catch(error) {
      toast.error(error.message);
      dispatch(networkError(error.message));
    }
  }
};

const fetchAdminActiveandUpcomingChallenges = (id) => {
  const AuthToken = localStorage.getItem('AUTH_TOKEN');
  return async (dispatch) => {
    try {
      const res = await http.get(`${GetAdminActiveandUpcomingChallenges}/${id}`, {headers: { AUTHTOKEN: AuthToken }});
      if(res.message) {
        toast.error(res.message);
      } else {
        dispatch({type: actionTypes.ADMIN_CHALLENGE_ACTIVE, adminChallengeActive: res['active_challenges']});
      }
    } catch(error) {
      toast.error(error.message);
      dispatch(networkError(error.message));
    }
  }
};

const saveChallengeInformation = (name, value) => (dispatch) => dispatch({type: actionTypes.SAVE_CHALLENGE_INFORMATION, name: name, value: value});

const getChallengeInformation = () => (dispatch) => dispatch({type: actionTypes.GET_CHALLENGE_INFORMATION});

export { getChallengeOverview, challengesTopParticipants, challengesTopLeaderboard, fetchChallengesDashBoardAPI, activityChallengeAPI, behaviorChallengeAPI, getTopChallenges, saveChallengeInformation, getChallengeInformation, getAdminChallengeOverview, fetchAdminActiveandUpcomingChallenges };
